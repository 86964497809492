@import "minima";

.anchorjs-link:link {
    color: #aaa;
}

.anchorjs-link:visited {
    color: #aaa;
}

.anchorjs-link:hover {
    color: #2a7ae2;
}
